import { callToActionFields } from '../elements/CallToAction.builder';

export const config = {
    name: 'Pricing',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Plans & Pricing',
        },
        {
            name: 'cards',
            type: 'list',
            required: true,
            defaultValue: [
                {
                    heading: 'Individual',
                    description: 'Plans with licensing for small businesses, freelancers, and hobbyists.',
                    icon: '/icons/individual-v2.svg',
                    text: 'See Plans',
                    href: '/pricing',
                },
                {
                    heading: 'Business',
                    description: 'Plans with licensing and features for creative teams and larger companies.',
                    icon: '/icons/business.svg',
                    text: 'Learn More',
                    href: '/pricing',
                },
            ],
            onChange: (options) => {
                if (options.get('cards').length > 4) {
                    options.set('cards', options.get('cards').slice(0, 4));
                    alert('Maximum number of cards is 4. Modify an existing card instead.');
                }
            },
            subFields: [
                {
                    name: 'heading',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'description',
                    type: 'richText',
                    required: true,
                },
                {
                    name: 'icon',
                    type: 'string',
                    required: true,
                    helperText: "Path to image asset, relative to '/assets/common/images'",
                    regex: {
                        pattern: '^/.*',
                        options: '',
                        message:
                            "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
                    },
                },
                ...callToActionFields,
            ],
        },
    ],
};
