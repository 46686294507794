'use client';

import { BuilderComponent, useIsPreviewing } from '@builder.io/react';
import { builder } from '@builder.io/sdk';
import '@builder.io/widgets';

import './components/blocks';

//TODO: abstract out builder init to config/secret

interface BuilderPageProps {
    content: any;
    modelName?: string;
    apiKey?: string;
    countryCode: string;
    data?: any;
}

// Using <Link/> was causing search pages to pre-fetch and trigger extra search events.
// Temporarily removing this until we come up with a long term solution.
// function CustomLink(props: any) {
//     return <Link {...props} />;
// }

export default function RenderBuilderContent({
    content,
    modelName = 'page',
    apiKey,
    countryCode,
    data,
}: BuilderPageProps) {
    if (!apiKey) {
        throw new Error('Builder API key is not provided.');
    }
    builder.init(apiKey);
    builder.apiVersion = 'v3';
    const isPreviewing = useIsPreviewing();

    const show404 = !content && !isPreviewing;

    if (!show404) {
        return <BuilderComponent content={content} model={modelName} locale={countryCode} data={data} />;
    } else {
        //notFound();
    }
}

export function setBuilderUserAttributes(options: Record<string, string | boolean>) {
    builder.setUserAttributes(options);
}
