import { callToActionInputs } from '../elements/CallToAction.builder';

export const config = {
    name: 'Youtube',
    inputs: [
        {
            name: 'isPrimaryView',
            type: 'boolean',
            defaultValue: true,
        },
        {
            name: 'paragraph',
            type: 'richText',
            defaultValue:
                "Recognizing the need for diverse representation in entertainment, Shannon has made it her mission as a storyteller to represent the LGBTQIA+ community through her work. While Shannon still loves creating her own content, she’s recently delved into directing and producing music videos for artists like Joy Oladokun, Alyson Stoner, and Fletcher. She's also produced some stunning narrative shorts and has received several awards for her work. Shannon is a board member for the Love Loud Foundation and hopes to continue her mission to foster an inclusive future for everyone. See more of Shannon's work on her Youtube channel and Instagram.",
        },
        {
            name: 'videoId',
            type: 'string',
            required: true,
            defaultValue: 'JtitcwwEl-A',
        },
        { name: 'darkMode', type: 'boolean', defaultValue: false },
        {
            name: 'imagesFirst',
            type: 'boolean',
            required: true,
            defaultValue: false,
        },
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: '[Heading]',
        },
        {
            name: 'subheading',
            type: 'string',
            defaultValue: 'Create with storyblocks',
        },
        ...callToActionInputs,
    ],
};
