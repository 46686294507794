import { StackAlignment, StackDirection } from '@videoblocks/storywind';

export const config = {
    name: 'Stack',
    inputs: [
        {
            name: 'direction',
            type: 'string',
            defaultValue: StackDirection.Column,
            enum: [
                {
                    label: 'Column',
                    value: StackDirection.Column,
                },
                {
                    label: 'Row',
                    value: StackDirection.Row,
                },
            ],
        },
        {
            name: 'alignment',
            type: 'string',
            defaultValue: StackAlignment.Left,
            enum: [
                {
                    label: 'Left',
                    value: StackAlignment.Left,
                },
                {
                    label: 'Center',
                    value: StackAlignment.Center,
                },
            ],
        },
        {
            name: 'xGap',
            type: 'number',
            required: false,
        },
        {
            name: 'yGap',
            type: 'number',
            required: false,
        },
    ],
    defaultChildren: [
        {
            '@type': '@builder.io/sdk:Element',
            component: { name: 'Text', options: { text: 'Text goes here.' } },
        },
        {
            '@type': '@builder.io/sdk:Element',
            component: { name: 'Text', options: { text: 'Text goes here.' } },
        },
    ],
};
