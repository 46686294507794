import { lazyLoadDefaultConfig } from '../utils/constants';

export const config = {
    name: 'SEO Text And Image',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Save time and money with stock footage',
        },
        {
            name: 'image',
            type: 'string',
            helperText: "Path to image asset, relative to '/assets/common/images'",
            regex: {
                pattern: '^/.*',
                options: '',
                message: "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
            },
            required: true,
            defaultValue: '/marketing/diversity/article-preview-1.jpg', // Placeholder Image for Testing
        },
        {
            name: 'imageAlt',
            type: 'string',
            defaultValue: '',
        },
        {
            ...lazyLoadDefaultConfig,
        },
        {
            name: 'content',
            type: 'richText',
            defaultValue: `Using stock footage saves video producers time and money, and our library offers 
            a nearly endless supply of content. Whether you need space footage, videos of real people or 
            even green screen footage, we have what you need. Wrap any documentary, finish any YouTube video, 
            and nail any news story with high-quality HD and 4k stock video from Storyblocks.`,
            required: true,
        },
    ],
};
