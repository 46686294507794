enum Alignment {
    Left = 'text-left',
    Center = 'text-center',
}

enum FontFamily {
    PolySans = 'font-polysans',
    Inter = 'font-inter',
}

const headerAlignmentFontInputs = [
    {
        name: 'fontFamily',
        type: 'string',
        defaultValue: FontFamily.PolySans,
        required: true,
        enum: [
            {
                label: 'PolySans',
                value: FontFamily.PolySans,
            },
            {
                label: 'Inter',
                value: FontFamily.Inter,
            },
        ],
    },
    {
        name: 'headingAlignment',
        type: 'string',
        required: true,
        defaultValue: Alignment.Center,
        enum: [
            {
                label: 'Left',
                value: Alignment.Left,
            },
            {
                label: 'Center',
                value: Alignment.Center,
            },
        ],
    },
];

export default headerAlignmentFontInputs;

export { Alignment, FontFamily };
