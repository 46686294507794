import { Theme } from '@videoblocks/storywind';

export const config = {
    name: 'Tab',
    inputs: [
        {
            name: 'label',
            type: 'string',
            required: true,
        },
        {
            name: 'theme',
            type: 'string',
            defaultValue: Theme.LightMode,
            enum: [
                {
                    label: 'Light Mode',
                    value: Theme.LightMode,
                },
                {
                    label: 'Dark Mode',
                    value: Theme.DarkMode,
                },
            ],
        },
    ],
};
