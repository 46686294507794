export const config = {
    name: 'Site Map Category',
    inputs: [
        {
            name: 'heading',
            type: 'object',
            required: true,
            defaultValue: { href: '/', text: '[Category]' },
            subFields: [
                {
                    name: 'href',
                    type: 'string',
                    defaultValue: '/',
                },
                {
                    name: 'text',
                    type: 'string',
                    required: true,
                    defaultValue: '[Category]',
                },
            ],
        },
        {
            name: 'siteLinks',
            type: 'list',
            required: true,
            defaultValue: [{ href: '/', text: '[Link]' }],
            subFields: [
                {
                    name: 'href',
                    type: 'string',
                    required: true,
                    defaultValue: '/',
                },
                {
                    name: 'text',
                    type: 'string',
                    required: true,
                    defaultValue: '[Link]',
                },
            ],
        },
    ],
};
