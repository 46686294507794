// helper to get/construct asset source for uploaded builder assets
const imagesUrl = process.env.NEXT_PUBLIC_ASSET_PATH ?? '/images';

export default function getAssetSource(src: string = '') {
    if (src === '') return '';
    if (src?.startsWith('https')) return src;

    const path = src.startsWith('/') ? src : `/${src}`;
    return `${imagesUrl}${path}`;
}
