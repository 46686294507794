export const config = {
    name: 'Search Bar',
    inputs: [
        {
            name: 'imagesUrl',
            type: 'string',
            required: true,
            defaultValue: 'https://d3g7htsbjjywiv.cloudfront.net/assets/common/images',
        },
        {
            name: 'searchOrigin',
            type: 'string',
            required: true,
            defaultValue: 'top_nav',
        },
        {
            name: 'useSnapshotsUI',
            type: 'boolean',
            defaultValue: false,
        },
    ],
};
