import headerAlignmentFontInputs from '../utils/headerAlignmentFontInputs';

export const config = {
    name: 'Content Carousel',
    inputs: [
        {
            name: 'backgroundColor',
            type: 'string',
            defaultValue: 'gray-100',
            helperText: 'Specify a tailwind color',
        },
        {
            name: 'heading',
            type: 'string',
            required: false,
            defaultValue: 'Streamline your creative process',
        },
        {
            name: 'subHeading',
            type: 'string',
            required: false,
            defaultValue: ' ',
        },
        {
            name: 'contentCarouselTabs',
            type: 'list',
            required: true,
            subFields: [
                {
                    name: 'tabVideoSrc',
                    type: 'file',
                    allowedFileTypes: ['webm', 'mp4'],
                    defaultValue: '/home/video-homepage-hero-background.webm',
                    helperText: 'upload video file',
                },
                {
                    name: 'tabHeader',
                    type: 'string',
                    required: true,
                    defaultValue: 'Extensive media library',
                    helperText: 'Enter the header for the tab',
                },
                {
                    name: 'tabParagraph',
                    type: 'string',

                    required: true,
                    defaultValue: 'Video, Templates, Audio, and Images for all your creative needs',
                    helperText: 'Enter the paragraph for the tab',
                },
                {
                    name: 'tabUrlName',
                    type: 'string',
                    required: true,
                    defaultValue: 'Explore library',
                    helperText: 'Enter the text for the tab url',
                },
                {
                    name: 'tabUrl',
                    type: 'string',
                    required: true,
                    defaultValue: '/',
                    helperText: 'Enter the url for the tab',
                },
            ],
            onChange: (options) => {
                const maxTabs = 4;
                if (options.get('contentCarouselTabs').length > maxTabs) {
                    options.set('contentCarouselTabs', options.get('contentCarouselTabs').slice(0, maxTabs));
                    alert('Maximum number of tabs is 4. Modify an existing tab instead.');
                }
            },
            defaultValue: [
                {
                    tabVideoSrc: null,
                    tabHeader: 'Extensive media library',
                    tabParagraph: 'Video, Templates, Audio, and Images for all your creative needs',
                    tabUrlName: 'Explore library',
                    tabUrl: '',
                },
                {
                    tabVideoSrc: null,
                    tabHeader: 'Integrate with Adobe',
                    tabParagraph: 'Access Storyblocks directly in Premiere Pro and After Effects',
                    tabUrlName: 'Learn more',
                    tabUrl: '',
                },
                {
                    tabVideoSrc: null,
                    tabHeader: 'Organize your assets',
                    tabParagraph: 'Streamline your creative process by organizing all your assets',
                    tabUrlName: 'Create free account',
                    tabUrl: '',
                },
                {
                    tabVideoSrc: null,
                    tabHeader: 'Make a video',
                    tabParagraph: 'Create short-form video with our online video editor',
                    tabUrlName: 'Explore Maker',
                    tabUrl: '',
                },
            ],
        },
        ...headerAlignmentFontInputs,
    ],
};
