import { lazyLoadDefaultConfig } from '../utils/constants';

export const config = {
    name: 'Testimonials',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Trusted by production companies across the world',
        },
        {
            name: 'testimonials',
            type: 'list',
            required: true,
            defaultValue: [
                {
                    name: 'title, company name',
                    quote: '“I love having instant access to these amazing clips to use in my video projects! Knowning I have unlimited downloaded - it lets me test out different clip types to use for my projects!”',
                    avatar: '/youtube/bryan-francisco.jpg',
                    lazyLoadImage: true,
                },
                {
                    name: 'title, company name',
                    quote: '“I love having instant access to these amazing clips to use in my video projects! Knowning I have unlimited downloaded - it lets me test out different clip types to use for my projects!”',
                    avatar: '/youtube/cody-wanner.jpg',
                    lazyLoadImage: true,
                },
                {
                    name: 'title, company name',
                    quote: '“I love having instant access to these amazing clips to use in my video projects! Knowning I have unlimited downloaded - it lets me test out different clip types to use for my projects!”',
                    avatar: '/youtube/currently-hannah.jpg',
                    lazyLoadImage: true,
                },
            ],
            onChange: (options) => {
                if (options.get('testimonials').length > 3) {
                    options.set('testimonials', options.get('testimonials').slice(0, 3));
                    alert('Maximum number of testimonials is 3. Modify an existing testimonial instead.');
                }
            },
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                    required: true,
                    defaultValue: '[Name]',
                },
                {
                    name: 'quote',
                    type: 'richText',
                    required: true,
                    defaultValue: '[Quote]',
                },
                {
                    name: 'avatar',
                    type: 'string',
                    required: true,
                    defaultValue: '/icons/individual.svg',
                },
                {
                    name: 'href',
                    type: 'string',
                    required: true,
                    defaultValue: '/',
                },
                {
                    ...lazyLoadDefaultConfig,
                },
            ],
        },
    ],
};
