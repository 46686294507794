export const config = {
    name: 'Genres',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Genres',
        },
        {
            name: 'genres',
            type: 'list',
            required: true,
            helperText: 'A list of genres that link to the search page',
            defaultValue: [
                {
                    label: 'Ambient',
                    href: '/audio/search?media-type=music&categories=ambient',
                    imageSrc: '/genres/ambient.png',
                    showNewLabel: false,
                },
                {
                    label: 'Blues',
                    href: '/audio/search?media-type=music&categories=blues',
                    imageSrc: '/genres/blues.png',
                    showNewLabel: false,
                },
                {
                    label: 'Bumpers & Stingers',
                    href: '/audio/search?media-type=music&categories=bumpers-and-stingers',
                    imageSrc: '/genres/bumperstings.png',
                    showNewLabel: false,
                },
                {
                    label: 'Chill Out',
                    href: '/audio/search?media-type=music&categories=chill-out',
                    imageSrc: '/genres/chillout.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Cinematic',
                    href: '/audio/search?media-type=music&categories=cinematic',
                    imageSrc: '/genres/cinematic2.png',
                    showNewLabel: false,
                },
                {
                    label: 'Classical',
                    href: '/audio/search?media-type=music&categories=classical',
                    imageSrc: '/genres/classical.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Corporate',
                    href: '/audio/search?media-type=music&categories=corporate',
                    imageSrc: '/genres/corporate.png',
                    showNewLabel: false,
                },
                {
                    label: 'Country',
                    href: '/audio/search?media-type=music&categories=country',
                    imageSrc: '/genres/country.png',
                    showNewLabel: false,
                },
                {
                    label: 'Electronic',
                    href: '/audio/search?media-type=music&categories=electronic',
                    imageSrc: '/genres/electronic.png',
                    showNewLabel: false,
                },
                {
                    label: 'Folk',
                    href: '/audio/search?media-type=music&categories=folk',
                    imageSrc: '/genres/folk.png',
                    showNewLabel: false,
                },
                {
                    label: 'Hip Hop',
                    href: '/audio/search?media-type=music&categories=hip-hop',
                    imageSrc: '/genres/hiphop2.png',
                    showNewLabel: false,
                },
                {
                    label: 'Horror',
                    href: '/audio/search?media-type=music&categories=horror',
                    imageSrc: '/genres/horror2.png',
                    showNewLabel: false,
                },
                {
                    label: 'Holidays & Special Events',
                    href: '/audio/search?media-type=music&categories=holidays-and-special-events',
                    imageSrc: '/genres/holidaysspecialevents.png',
                    showNewLabel: false,
                },
                {
                    label: 'Jazz',
                    href: '/audio/search?media-type=music&categories=jazz',
                    imageSrc: '/genres/jazz.png',
                    showNewLabel: false,
                },
                {
                    label: 'Kids & Family',
                    href: '/audio/search?media-type=music&categories=kids-and-family',
                    imageSrc: '/genres/kidsfamily.png',
                    showNewLabel: false,
                },
                {
                    label: 'Pop',
                    href: '/audio/search?media-type=music&categories=pop',
                    imageSrc: '/genres/pop.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'R&B',
                    href: '/audio/search?media-type=music&categories=rhythm-and-blues',
                    imageSrc: '/genres/rb.png',
                    showNewLabel: false,
                },
                {
                    label: 'Religious',
                    href: '/audio/search?media-type=music&categories=religious',
                    imageSrc: '/genres/religious.png',
                    showNewLabel: false,
                },
                {
                    label: 'Rock',
                    href: '/audio/search?media-type=music&categories=rock',
                    imageSrc: '/genres/rock.png',
                    showNewLabel: false,
                },
                {
                    label: 'World',
                    href: '/audio/search?media-type=music&categories=world',
                    imageSrc: '/genres/world2.jpg',
                    showNewLabel: false,
                },
            ],
            subFields: [
                {
                    name: 'label',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'href',
                    type: 'string',
                    required: true,
                },
                {
                    friendlyName: 'Image Source',
                    name: 'imageSrc',
                    type: 'string',
                    required: true,
                    helperText: "Path to image asset, relative to '/assets/common/images'",
                    regex: {
                        pattern: '^/.*',
                        options: '',
                        message:
                            "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
                    },
                },
                {
                    name: 'showNewLabel',
                    type: 'boolean',
                    required: true,
                    defaultValue: false,
                },
            ],
        },
    ],
};
