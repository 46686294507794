'use client';

import { incrementTelemetry, timerTelemetry } from 'core-events';
import { usePathname } from 'next/navigation';
import React, { useState, useEffect } from 'react';
import StockblocksClient from 'stockblocks-client';

import {
    getSearchUrl,
    SearchFilterContentTypes,
    searchOrigins,
    ProminentSearch,
    fetchTypeahead,
    SearchOption,
} from '@videoblocks/shared-components';

function getCookieByName(cookieName: string): string | undefined {
    const cookieRegex = new RegExp(`(^|; )${cookieName}=(.*?)($|;)`);
    return document.cookie.match(cookieRegex)?.[2];
}

interface ProminentSearchContainerProps {
    imagesUrl: string;
    contentTypes: SearchOption[];
    contentType: SearchFilterContentTypes;
    setContentType: (newContentType: SearchFilterContentTypes) => void;
    showDropdown?: boolean;
    alignment?: 'left' | 'center';
    showContentToggles?: boolean;
}

const ProminentSearchContainer = ({
    imagesUrl,
    contentTypes,
    contentType,
    setContentType,
    showDropdown = true,
    alignment = 'left',
    showContentToggles,
}: ProminentSearchContainerProps) => {
    const pathname = usePathname();

    const [auth, setAuth] = useState<{
        hasAnySubscription?: boolean;
        isEnterprise?: boolean;
        permissions?: {
            pproPluginEnabled?: boolean;
        };
    }>({});

    const handleSubmit = (contentType: string, searchTerm: string, searchOrigin: string) => {
        const searchUrl = getSearchUrl({
            contentType: contentType as SearchFilterContentTypes,
            searchTerm,
            searchOrigin: searchOrigin || searchOrigins.SEARCH_BAR,
        });
        window.location.href = searchUrl;
    };
    useEffect(() => {
        const token = getCookieByName('login_session');
        const adminToken = getCookieByName('AUID');
        StockblocksClient.getUserData(token, adminToken).then((data) => {
            setAuth(data.auth);
        });
    }, []);

    const { hasAnySubscription = false, isEnterprise = false, permissions: { pproPluginEnabled = false } = {} } = auth;

    return (
        <ProminentSearch
            contentType={contentType}
            onDropdownChange={(contentType) => {
                setContentType(contentType as SearchFilterContentTypes);
            }}
            onSubmit={handleSubmit}
            searchTerm={''}
            userHasAnySubscription={hasAnySubscription}
            fetchTypeahead={(
                contentType: SearchFilterContentTypes,
                query = '',
                typeaheadEnhancementExperiment = false,
            ) =>
                fetchTypeahead(contentType, query, typeaheadEnhancementExperiment, async () =>
                    timerTelemetry('search.frontEnd.typeAhead'),
                )
            }
            isEnterpriseMember={isEnterprise}
            pproPluginEnabled={pproPluginEnabled}
            isSearchAppLoaded={() => false}
            incrementTelemetry={incrementTelemetry}
            imagesUrl={imagesUrl}
            prominentSearchOptions={contentTypes}
            contentTypeFromPrevious={contentType || SearchFilterContentTypes.All_videos_content_type}
            showDropDown={showDropdown}
            showContentToggles={showContentToggles || ['/', '/audio'].includes(pathname)}
            alignment={alignment}
        />
    );
};

export default ProminentSearchContainer;
