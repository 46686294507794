export const configV1 = {
    name: 'Hubspot',
    hideFromInsertMenu: true,
    inputs: [
        {
            name: 'formId',
            type: 'string',
            required: true,
            defaultValue: 'c3514b58-be2d-415e-9fd0-51866219148c',
        },
    ],
};

export const configV2 = {
    name: 'v2:Hubspot',
    hideFromInsertMenu: true,
    inputs: [
        {
            name: 'formId',
            type: 'string',
            required: true,
            defaultValue: 'c3514b58-be2d-415e-9fd0-51866219148c',
        },
        {
            name: 'loadingID',
            type: 'string',
            required: true,
            defaultValue: 'hubspot-form',
            helperText: 'Change this value if there are 2 hubspot forms on the same page',
        },
        {
            name: 'includeChiliPiper',
            type: 'boolean',
            required: true,
            defaultValue: false,
        },
    ],
};

export const configV3 = {
    name: 'v3:Hubspot',
    inputs: [
        {
            name: 'formId',
            type: 'string',
            required: true,
            defaultValue: 'c3514b58-be2d-415e-9fd0-51866219148c',
        },
        {
            name: 'loadingID',
            type: 'string',
            required: true,
            defaultValue: 'hubspot-form',
            helperText: 'Change this value if there are 2 hubspot forms on the same page',
        },
        {
            name: 'includeChiliPiper',
            type: 'boolean',
            required: true,
            defaultValue: false,
        },
        {
            name: 'routerName',
            type: 'string',
            required: true,
            defaultValue: 'inbound-router',
            showIf: (options) => options.get('includeChiliPiper'),
        }
        
    ],
};
