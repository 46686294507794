import { callToActionInputs } from '../elements/CallToAction.builder';

export const config = {
    name: 'Wistia',
    inputs: [
        {
            name: 'isPrimaryView',
            type: 'boolean',
            defaultValue: true,
        },
        {
            name: 'paragraph',
            type: 'richText',
            defaultValue:
                'There’s a simple template that creates the foundation for any story — the 3-act structure. In this episode, filmmakers and veteran YouTube creators Colin and Samir walk through how they built one of their most successful videos from a strong 3-act structure.',
        },
        {
            name: 'videoId',
            type: 'string',
            required: true,
            defaultValue: 'stirvyriq4',
        },
        { name: 'darkMode', type: 'boolean', defaultValue: false },
        {
            name: 'imagesFirst',
            type: 'boolean',
            required: true,
            defaultValue: false,
        },
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: '[Heading]',
        },
        {
            name: 'subheading',
            type: 'string',
            defaultValue: 'Create with storyblocks',
        },
        ...callToActionInputs,
    ],
};
