export const config = {
    name: 'FAQ',
    inputs: [
        {
            name: 'faqItems',
            type: 'list',
            required: true,
            defaultValue: [{ question: 'Question', answer: 'Answer' }],
            subFields: [
                {
                    name: 'question',
                    type: 'string',
                    required: true,
                    defaultValue: 'Question',
                },
                {
                    name: 'answer',
                    type: 'richText',
                    required: true,
                    defaultValue: 'Answer',
                },
            ],
        },
        {
            name: 'showViewAllFAQButton',
            type: 'boolean',
            defaultValue: true,
        },
    ],
};
