import { callToActionInputs } from '../elements/CallToAction.builder';
import { targetAttributeOptions, lazyLoadDefaultConfig } from '../utils/constants';
import headerAlignmentFontInputs from '../utils/headerAlignmentFontInputs';

export const config = {
    name: 'Articles',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Tips & Tutorials',
        },
        {
            name: 'subheading',
            type: 'richText',
            defaultValue: '',
            onChange: (options) => {
                const characterLimit = 240;
                const subheadingText = options.get('subheading');
                const htmlTagRegex = /<.*?>/gi;

                const textOnly = subheadingText.replaceAll(htmlTagRegex, '');
                if (textOnly.length <= characterLimit) {
                    return;
                }

                const tokenizedTags = [...subheadingText.matchAll(htmlTagRegex)].map((item) => item[0]);
                const tokenizedText = subheadingText.split(htmlTagRegex);

                const trimmedTextNodes = tokenizedText.reduce((textNodes, text) => {
                    const currentTextLength = textNodes.join('').length;
                    return currentTextLength + text.length <= characterLimit
                        ? textNodes.concat(text)
                        : textNodes.concat(text.slice(0, characterLimit - currentTextLength));
                }, []);

                const trimmedRichText = tokenizedTags.map((tag, i) => trimmedTextNodes[i] + tag).join('');

                alert(`Max of ${characterLimit} characters allowed.`);
                options.set('subheading', trimmedRichText);
            },
        },
        {
            name: 'articles',
            type: 'list',
            required: true,
            defaultValue: [
                {
                    name: '[Article Name]',
                    articleHeading: '[Article Heading]',
                    link: 'https://blog.storyblocks.com',
                    imageSrc: '/youtube/monetization.jpg',
                    target: '_blank',
                    relativeLink: 'noopener',
                },
                {
                    name: '[Article Name]',
                    articleHeading: '[Article Heading]',
                    link: 'https://blog.storyblocks.com',
                    imageSrc: '/youtube/monetization.jpg',
                    target: '_blank',
                    relativeLink: 'noopener',
                },
                {
                    name: '[Article Name]',
                    articleHeading: '[Article Heading]',
                    link: 'https://blog.storyblocks.com',
                    imageSrc: '/youtube/monetization.jpg',
                    target: '_blank',
                    relativeLink: 'noopener',
                },
            ],
            onChange: (options) => {
                if (options.get('articles').length > 3) {
                    options.set('articles', options.get('articles').slice(0, 3));
                    alert('Maximum number of articles is 3. Modify an existing article instead.');
                }
            },
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                    required: true,
                    defaultValue: '[Article Name]',
                },
                {
                    name: 'articleHeading',
                    type: 'string',
                    defaultValue: '[Article Heading]',
                },
                {
                    name: 'link',
                    type: 'string',
                    required: true,
                    defaultValue: 'https://blog.storyblocks.com',
                },
                {
                    name: 'imageSrc',
                    type: 'string',
                    required: true,
                    defaultValue: '/youtube/monetization.jpg',
                },
                {
                    name: 'imageSourceAlt',
                    type: 'string',
                    helperText: 'Image alternative text',
                },
                {
                    name: 'target',
                    type: 'string',
                    enum: targetAttributeOptions,
                    required: true,
                    defaultValue: '_blank',
                    helperText: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target',
                },
                {
                    name: 'relativeLink',
                    type: 'string',
                    required: true,
                    defaultValue: 'noopener',
                    helperText: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-rel',
                },
            ],
        },
        {
            ...lazyLoadDefaultConfig,
        },
        ...headerAlignmentFontInputs,
        ...callToActionInputs,
    ],
};
