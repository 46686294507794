export const config = {
    name: 'Benefits (v1)',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Why Storyblocks?',
        },
        {
            name: 'benefits',
            type: 'list',
            required: true,
            defaultValue: [
                {
                    heading: 'Unbeatable Savings',
                    text: 'Our subscription model gives you way more content for your money. We offer a flexible plan for every budget.',
                    icon: '/media-page/redesigned-footage/bag.svg',
                },
                {
                    heading: 'Royalty-Free',
                    text: 'All of our royalty free audio content can be used multiple times for commercial and personal projects.',
                    icon: '/media-page/redesigned-footage/ribbon.svg',
                },
                {
                    heading: 'Fresh Content',
                    text: 'We’re always adding new content based on what you’re searching for.',
                    icon: '/media-page/redesigned-footage/star.svg',
                },
                {
                    heading: 'Yours Forever',
                    text: 'Anything you download is yours to keep and use forever.',
                    icon: '/media-page/redesigned-footage/bookmark.svg',
                },
            ],
            onChange: (options) => {
                if (options.get('benefits').length > 4) {
                    options.set('benefits', options.get('benefits').slice(0, 4));
                    alert('Maximum number of benefits is 4. Modify an existing benefit instead.');
                }
            },
            subFields: [
                {
                    name: 'heading',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'text',
                    type: 'richText',
                    required: true,
                },
                {
                    name: 'icon',
                    type: 'string',
                    required: true,
                    helperText: "Path to image asset, relative to '/assets/common/images'",
                    regex: {
                        pattern: '^/.*',
                        options: '',
                        message:
                            "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
                    },
                },
            ],
        },
    ],
};
