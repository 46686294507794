import { FontFamily } from '../utils/headerAlignmentFontInputs';

export const config = {
    name: 'Assets Counter',
    inputs: [
        {
            name: 'darkMode',
            type: 'boolean',
            required: true,
            defaultValue: false,
        },
        {
            name: 'numbers',
            type: 'boolean',
            required: true,
            defaultValue: true,
        },
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Unlimited access to a constantly growing library at your fingertips',
        },
        {
            name: 'subheading',
            type: 'string',
            required: true,
            defaultValue: 'unlimited library',
        },
        {
            name: 'fontFamily',
            type: 'string',
            defaultValue: FontFamily.PolySans,
            required: true,
            enum: [
                {
                    label: 'PolySans',
                    value: FontFamily.PolySans,
                },
                {
                    label: 'Inter',
                    value: FontFamily.Inter,
                },
            ],
        },
        {
            name: 'videoFeaturesIcon',
            type: 'string',
            required: true,
            defaultValue: 'video_icon',
            helperText:
                "text '_black.svg' or '_white.svg' will be appended automatically at the end of icon source for dark/white mode",
        },
        {
            name: 'audioFeaturesIcon',
            type: 'string',
            required: true,
            defaultValue: 'music_icon',
            helperText:
                "text '_black.svg' or '_white.svg' will be appended automatically at the end of icon source for dark/white mode",
        },
        {
            name: 'imageFeaturesIcon',
            type: 'string',
            required: true,
            defaultValue: 'image_icon',
            helperText:
                "text '_black.svg' or '_white.svg' will be appended automatically at the end of icon source for dark/white mode",
        },
        {
            name: 'videoFeatures',
            type: 'list',
            required: true,
            defaultValue: [
                { feature: 'HD and 4K Footage', link: '/video/footage' },
                {
                    feature: 'After Effects Templates',
                    link: '/video/after-effects/templates',
                },
                { feature: 'Motion Backgrounds', link: '/video/motion-backgrounds' },
            ],
            subFields: [
                {
                    name: 'feature',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'link',
                    type: 'string',
                    required: true,
                },
            ],
        },
        {
            name: 'audioFeatures',
            type: 'list',
            required: true,
            defaultValue: [
                { feature: 'Music Tracks', link: '/audio/music' },
                { feature: 'Sound Effects', link: '/audio/sound-effects' },
            ],
            subFields: [
                {
                    name: 'feature',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'link',
                    type: 'string',
                    required: true,
                },
            ],
        },
        {
            name: 'imageFeatures',
            type: 'list',
            required: true,
            defaultValue: [
                { feature: 'Photos', link: '/images/photos' },
                { feature: 'Vectors', link: '/images/vectors' },
                { feature: 'Illustrations', link: '/images/illustrations' },
            ],
            subFields: [
                {
                    name: 'feature',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'link',
                    type: 'string',
                    required: true,
                },
            ],
        },
    ],
};
