import { callToActionInputs } from '../elements/CallToAction.builder';
import { lazyLoadDefaultConfig } from '../utils/constants';

export const config = {
    name: 'Image And Text',
    inputs: [
        {
            name: 'darkMode',
            type: 'boolean',
            required: true,
            defaultValue: false,
        },
        {
            name: 'imagesFirst',
            type: 'boolean',
            required: true,
            defaultValue: false,
        },
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: '[Heading]',
        },
        {
            name: 'subheading',
            type: 'string',
            defaultValue: 'Create with storyblocks',
        },
        {
            name: 'featureBullets',
            type: 'list',
            required: false,
            defaultValue: [{ icon: '/icons/video_icon', text: 'Cool feature!' }],
            subFields: [
                {
                    name: 'icon',
                    type: 'string',
                    required: true,
                    defaultValue: '/icons/video_icon',
                    helperText:
                        "text '_black.svg' or '_white.svg' will be appended automatically at the end of icon source for dark/white mode",
                },
                {
                    name: 'text',
                    type: 'string',
                    required: true,
                    defaultValue: 'Cool feature!',
                },
            ],
            onChange: (options) => {
                if (options.get('featureBullets').length > 4) {
                    options.set('featureBullets', options.get('featureBullets').slice(0, 4));
                    alert('Maximum number of featured bullets is 4. Modify an existing featured bullet instead.');
                }
            },
        },
        {
            name: 'content',
            type: 'richText',
            defaultValue: `[Subheading]`,
            helperText: '320 character limit recommended',
        },
        {
            name: 'imageSource',
            type: 'string',
            required: true,
            defaultValue: '/featured-artists/liqwyd.jpg',
            helperText: "Path to image asset, relative to '/assets/common/images'",
        },
        {
            name: 'imageSourceAlt',
            type: 'string',
            helperText: 'Image alternative text',
        },
        {
            name: 'videoSource',
            type: 'string',
            helperText: "Path to video asset, relative to '/assets/common/images'",
        },
        {
            ...lazyLoadDefaultConfig,
        },
        ...callToActionInputs,
    ],
};
