import { callToActionInputs } from '../elements/CallToAction.builder';
import { lazyLoadDefaultConfig } from '../utils/constants';

export const config = {
    name: 'Video Hero (Legacy)',
    inputs: [
        {
            name: 'heading',
            type: 'longText',
            required: true,
            defaultValue: '[Heading]',
        },
        {
            name: 'subheading',
            type: 'longText',
            defaultValue: '[Subheading]',
        },
        {
            name: 'imageSource',
            type: 'string',
            required: true,
            defaultValue: '/media-page/redesigned-footage/footage-sizzle.jpg',
            helperText: "Path to image asset, relative to '/assets/common/images'",
        },
        {
            name: 'videoSource',
            type: 'string',
            defaultValue: '/media-page/redesigned-footage/footage-sizzle.webm',
            helperText: "Path to image asset, relative to '/assets/common/images'",
        },
        {
            ...lazyLoadDefaultConfig,
        },
        ...callToActionInputs,
    ],
};
