import Image, { type ImageProps } from 'next/image';
import React, { forwardRef, type ReactElement } from 'react';

interface RequiredImageProps extends ImageProps {
    src: string;
    shouldLazyLoad?: boolean;
}

interface ImagePropsWithSize extends RequiredImageProps {
    height: Required<ImageProps>['height'];
    width: Required<ImageProps>['width'];
}

interface ImagePropsWithFill extends RequiredImageProps {
    fill: Required<ImageProps['fill']>;
}

// next/image requires either height and width, or fill to be set
type ProgressiveImageProps = ImagePropsWithSize | ImagePropsWithFill;

const ProgressiveImage = forwardRef<HTMLImageElement, ProgressiveImageProps>(
    ({ id, className, src, alt = '', height, width, shouldLazyLoad = false, ...other }, ref): ReactElement => {
        const loadingType = shouldLazyLoad ? 'lazy' : 'eager';

        return (
            <Image
                ref={ref}
                className={className}
                id={id}
                src={src}
                alt={alt}
                width={width}
                height={height}
                loading={loadingType}
                {...other}
                placeholder="empty"
            />
        );
    },
);

ProgressiveImage.displayName = 'ProgressiveImage';

export default ProgressiveImage;
