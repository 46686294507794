import { callToActionInputs } from '../elements/CallToAction.builder';

export const config = {
    name: 'Footer Hook',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Subscribe to start downloading',
        },
        {
            name: 'subheading',
            type: 'string',
            required: true,
            defaultValue: 'Create With Storyblocks',
        },
        {
            name: 'content',
            type: 'richText',
            required: true,
            defaultValue:
                'Royalty-free and yours to keep forever, all of our content can be downloaded with a subscription',
        },
        ...callToActionInputs,
    ],
};
