import { lazyLoadDefaultConfig } from '../utils/constants';

export const config = {
    name: 'Featured Artists',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Featured Artists',
        },
        {
            name: 'featuredArtists',
            type: 'list',
            required: true,
            defaultValue: [
                {
                    href: '/audio/search?media-type=music&portal_artist_ids=BNgaSVTqoUkao4zccm',
                    text: 'Amber Waldron',
                    imageSource: '/featured-artists/amberwaldron.jpg',
                    lazyLoadImage: true,
                },
                {
                    href: '/audio/search?media-type=music&portal_artist_ids=SHliGr4jiUkaom6ayg',
                    text: 'Jontavious Willis',
                    imageSource: '/featured-artists/jontaviouswillis.jpg',
                    lazyLoadImage: true,
                },
                {
                    href: '/audio/search?media-type=music&portal_artist_ids=S4b7cm-iukpsnrczk',
                    text: 'Kaii Dreams',
                    imageSource: '/featured-artists/kaiidreams.jpg',
                    lazyLoadImage: true,
                },
                {
                    href: '/audio/search?portal_artist_ids=Hsx8DES0Jvkcpaqoyq',
                    text: 'Q-Rock',
                    imageSource: '/featured-artists/qrock.jpg',
                    lazyLoadImage: true,
                },
                {
                    href: '/audio/search?portal_artist_ids=rZYWIHMLUk80843mh',
                    text: 'Burning Heart',
                    imageSource: '/featured-artists/burningheart.png',
                    lazyLoadImage: true,
                },
                {
                    href: '/audio/search?media-type=music&portal_artist_ids=r--G8Hf8Uk80849sm',
                    text: 'LiQWYD',
                    imageSource: '/featured-artists/liqwyd.jpg',
                    lazyLoadImage: true,
                },
                {
                    href: '/audio/search?media-type=music&portal_artist_ids=rf7MlROIdknm8pfa7',
                    text: 'Matt Oakley',
                    imageSource: '/featured-artists/mattoakley.png',
                    lazyLoadImage: true,
                },
                {
                    href: '/audio/search?media-type=music&portal_artist_ids=HK9JdXbjukpsnf7fy',
                    text: 'Nite Owl',
                    imageSource: '/featured-artists/nite_owl_2.png',
                    lazyLoadImage: true,
                },
            ],
            subFields: [
                {
                    name: 'href',
                    type: 'string',
                    required: true,
                    defaultValue: '/',
                },
                {
                    name: 'text',
                    type: 'string',
                    required: true,
                    defaultValue: 'Artists',
                },
                {
                    name: 'imageSource',
                    type: 'string',
                    required: true,
                    defaultValue: '/featured-artists/',
                },
                {
                    ...lazyLoadDefaultConfig,
                },
            ],
            onChange: (options: any) => {
                if (options.get('featuredArtists').length > 8) {
                    options.set('featuredArtists', options.get('featuredArtists').slice(0, 8));
                    alert('Maximum number of featured artists is 8. Modify an existing featured artist instead.');
                }
            },
        },
    ],
};
