import { callToActionInputs } from '../elements/CallToAction.builder';
import { Background } from '../enums/BackgroundEnum';

export const config = {
    name: 'Card Section',
    inputs: [
        {
            name: 'smallHeader',
            type: 'string',
            required: true,
            defaultValue: 'The Storyblocks Difference',
        },
        {
            name: 'largeHeader',
            type: 'string',
            defaultValue: 'Why Storyblocks',
        },
        {
            name: 'paragraph',
            type: 'richText',
        },
        {
            name: 'background',
            type: 'string',
            enum: [
                {
                    label: 'gray',
                    value: Background.gray,
                },
                {
                    label: 'image',
                    value: Background.image,
                },
            ],
            defaultValue: Background.gray,
        },
        {
            name: 'numCardsShown',
            friendlyName: 'Number of cards to show',
            type: 'string',
            enum: ['3', '4', '6'],
            required: true,
            defaultValue: '3',
        },

        {
            name: 'cards',
            type: 'list',
            required: true,
            defaultValue: [
                {
                    cardHeader: 'Get Notifications Daily',
                    cardParagraph:
                        'Our subscription model gives you way more content for your money. We offer a flexible plan for every budget.',
                    cardIcon: '/icons/sb-image.svg',
                },
                {
                    cardHeader: 'Get Notifications Daily',
                    cardParagraph:
                        'Our subscription model gives you way more content for your money. We offer a flexible plan for every budget.',
                    cardIcon: '/icons/sb-image.svg',
                },
                {
                    cardHeader: 'Get Notifications Daily',
                    cardParagraph:
                        'Our subscription model gives you way more content for your money. We offer a flexible plan for every budget.',
                    cardIcon: '/icons/sb-image.svg',
                },
            ],
            onChange: (options) => {
                if (options.get('cards').length > 6) {
                    options.set('cards', options.get('cards').slice(0, 5));
                    alert('Maximum number of cards is 6. Modify an existing cards instead.');
                }
            },
            subFields: [
                { name: 'cardIcon', type: 'string', required: true },
                {
                    name: 'cardHeader',
                    type: 'string',
                    required: true,
                },
                { name: 'cardParagraph', type: 'richText', required: true },
            ],
        },
        ...callToActionInputs,
    ],
};
