export const config = {
    name: 'Text Hero',
    inputs: [
        {
            name: 'content',
            type: 'richText',
            defaultValue: `Give your users complete creative freedom to tell any story with access to a massive, growing library of high-resolution stock video, audio, and images.`,
            helperText: '320 character limit recommended',
        },
    ],
};
