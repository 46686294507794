/** Sort Options taken from SearchContainer.js */
const defaultSortOptions = [
    { value: 'most_relevant', label: 'Most Relevant' },
    { value: 'most_downloaded', label: 'Most Downloaded' },
    { value: 'most_recent', label: 'Most Recent' },
    { value: 'trending_now', label: 'Trending Now' },
    { value: 'undiscovered', label: 'Undiscovered' },
];

const audioSortOptions = [
    { value: 'duration_asc', label: 'Duration: Low-High' },
    { value: 'duration_desc', label: 'Duration: High-Low' },
    { value: 'title_asc', label: 'Title: A-Z' },
    { value: 'title_desc', label: 'Title: Z-A' },
];

const videoSortOptions = [{ value: 'best_aesthetics', label: 'Best Aesthetics' }];

const stockItemCardResultOptions = ['3', '6', '9', '12', '15'];
const MAX_RESULTS_PER_PAGE = Math.max(...stockItemCardResultOptions.map(Number));

const targetAttributeOptions = ['_self', '_blank', '_parent', '_top'];
const browseCategoriesMediaTypesOptions = [
    {
        label: 'All',
        value: 'all-videos',
    },
    {
        label: 'Footage',
        value: 'footage',
    },
    {
        label: 'Backgrounds',
        value: 'motion-backgrounds',
    },
    {
        label: 'Templates',
        value: 'templates',
    },
];

const browseModuleResultOptions = ['6', '9', '12', '15'];
const browseModuleResultMobileOptions = ['3'];

const opacityOptions = [
    {
        label: '45%',
        value: 0.45,
    },
    {
        label: '55%',
        value: 0.55,
    },
    {
        label: '60%',
        value: 0.6,
    },
    {
        label: '65%',
        value: 0.65,
    },
    {
        label: '70%',
        value: 0.7,
    },
    {
        label: '75%',
        value: 0.75,
    },
    {
        label: '80%',
        value: 0.8,
    },
    {
        label: '85%',
        value: 0.85,
    },
    {
        label: '90%',
        value: 0.9,
    },
    {
        label: '95%',
        value: 0.95,
    },
    {
        label: '100%',
        value: 1,
    },
];

const lazyLoadDefaultConfig = {
    name: 'lazyLoadImage',
    type: 'boolean',
    required: false,
    defaultValue: true,
};

export {
    MAX_RESULTS_PER_PAGE,
    audioSortOptions,
    defaultSortOptions,
    stockItemCardResultOptions,
    videoSortOptions,
    targetAttributeOptions,
    browseCategoriesMediaTypesOptions,
    browseModuleResultOptions,
    browseModuleResultMobileOptions,
    opacityOptions,
    lazyLoadDefaultConfig,
};
