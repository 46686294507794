export const config = {
    name: 'SEO Text',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Save time and money with stock footage',
        },
        {
            name: 'content',
            type: 'richText',
            required: true,
            defaultValue: `Using stock footage saves video producers time and money, and our library offers a nearly endless supply of content. Whether you need space footage, videos of real people or even green screen footage, we have what you need. Wrap any documentary, finish any YouTube video, and nail any news story with high-quality HD and 4k stock video from Storyblocks.`,
        },
    ],
};
