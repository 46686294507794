import { TextColor } from '../enums/TextColorEnum';
import { lazyLoadDefaultConfig, opacityOptions } from '../utils/constants';

export const config = {
    name: 'Thank You',
    inputs: [
        {
            name: 'useOpaqueBackground',
            type: 'boolean',
            required: false,
            defaultValue: true,
        },
        {
            name: 'opacity',
            type: 'object',
            defaultValue: {
                percentage: 0.95,
            },
            showIf: (options) => options.get('useOpaqueBackground'),
            subFields: [
                {
                    name: 'percentage',
                    type: 'string',
                    required: true,
                    enum: opacityOptions,
                },
            ],
        },
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Thank You!',
        },
        {
            name: 'subheading',
            type: 'longText',
            required: true,
            defaultValue: 'Someone from our sales team will reach out to you shortly.',
        },
        {
            ...lazyLoadDefaultConfig,
        },
        {
            name: 'textColor',
            required: true,
            type: 'string',
            enum: [
                {
                    label: 'gray',
                    value: TextColor.gray,
                },
                {
                    label: 'white',
                    value: TextColor.white,
                },
            ],
            defaultValue: TextColor.white,
        },
    ],
};
