import { callToActionInputs } from '../elements/CallToAction.builder';
import headerAlignmentFontInputs from '../utils/headerAlignmentFontInputs';

export const config = {
    name: 'Pills',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: '[Heading]',
        },
        ...headerAlignmentFontInputs,
        {
            name: 'pillAlignment',
            type: 'string',
            required: true,
            defaultValue: 'justify-center',
            enum: [
                {
                    label: 'Left',
                    value: 'justify-start',
                },
                {
                    label: 'Center',
                    value: 'justify-center',
                },
            ],
        },
        {
            name: 'pills',
            type: 'list',
            required: true,
            defaultValue: [{ href: '/', text: 'Pill' }],
            subFields: [
                {
                    name: 'href',
                    type: 'string',
                    required: true,
                    defaultValue: '/',
                },
                {
                    name: 'text',
                    type: 'string',
                    required: true,
                    defaultValue: 'Pill',
                },
            ],
        },
        {
            name: 'browseAllPill',
            type: 'object',
            subFields: [
                {
                    name: 'href',
                    type: 'string',
                    defaultValue: '/video/search',
                },
                {
                    name: 'text',
                    type: 'string',
                    defaultValue: 'Browse All',
                },
            ],
        },
        ...callToActionInputs,
    ],
};
