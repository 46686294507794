import { callToActionInputs } from '../elements/CallToAction.builder';

export const config = {
    name: 'Vimeo',
    inputs: [
        {
            name: 'isPrimaryView',
            type: 'boolean',
            defaultValue: true,
        },
        {
            name: 'paragraph',
            type: 'richText',
            defaultValue:
                'The demand for video is growing daily. Empower your teams to create faster than ever with unlimited stock downloads, iron-clad business licensing, and collaboration-first features to scale production easily and achieve your strategic goals.',
        },
        {
            name: 'videoId',
            type: 'string',
            required: true,
            defaultValue: '434170752',
        },
        { name: 'darkMode', type: 'boolean', defaultValue: false },
        {
            name: 'imagesFirst',
            type: 'boolean',
            required: true,
            defaultValue: false,
        },
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: '[Heading]',
        },
        {
            name: 'subheading',
            type: 'string',
            defaultValue: 'Create with storyblocks',
        },
        ...callToActionInputs,
    ],
};
