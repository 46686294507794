import { callToActionInputs, secondaryCallToActionInputs } from '../elements/CallToAction.builder';
import { TextColor } from '../enums/TextColorEnum';
import { lazyLoadDefaultConfig, opacityOptions } from '../utils/constants';

export const config = {
    name: 'Media Hero',
    inputs: [
        {
            name: 'verticalAlignment',
            type: 'string',
            required: false,
            defaultValue: 'middle',
            enum: [
                {
                    label: 'Middle',
                    value: 'middle',
                },
                {
                    label: 'Bottom',
                    value: 'bottom',
                },
            ],
        },
        {
            name: 'horizontalAlignment',
            type: 'string',
            required: false,
            defaultValue: 'center',
            enum: [
                {
                    label: 'Left',
                    value: 'left',
                },
                {
                    label: 'Center',
                    value: 'center',
                },
            ],
        },
        {
            name: 'useOpaqueBackground',
            type: 'boolean',
            required: false,
            defaultValue: false,
        },
        {
            name: 'opacity',
            type: 'object',
            defaultValue: {
                percentage: 0.45,
            },
            showIf: (options) => options.get('useOpaqueBackground'),
            subFields: [
                {
                    name: 'percentage',
                    type: 'string',
                    required: true,
                    enum: opacityOptions,
                },
            ],
        },
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: '[Heading]',
        },
        {
            name: 'subheading',
            type: 'longText',
            required: false,
            defaultValue: '[Subheading]',
        },
        {
            name: 'videoSource',
            // https://www.builder.io/c/docs/custom-components-input-types#code-strong-file-strong-code
            type: 'file',
            required: false,
            allowedFileTypes: ['webm', 'mp4'],
            defaultValue: '/assets/common/videos/placeholder.webm',
        },
        {
            name: 'imageSource',
            type: 'file',
            required: true,
            allowedFileTypes: ['jpg', 'png', 'webp'],
            defaultValue: '/assets/common/images/placeholder.png',
        },
        {
            name: 'imageSourceAlt',
            type: 'string',
            helperText: 'Image alternative text',
        },
        {
            ...lazyLoadDefaultConfig,
        },
        {
            name: 'tall',
            type: 'boolean',
            required: false,
            helperText: 'Tall vs. short widget',
            defaultValue: true,
        },
        {
            name: 'textColor',
            required: true,
            type: 'string',
            enum: [
                {
                    label: 'gray',
                    value: TextColor.gray,
                },
                {
                    label: 'white',
                    value: TextColor.white,
                },
            ],
            defaultValue: TextColor.white,
        },
        {
            name: 'backgroundColor',
            type: 'color',
            required: false,
            defaultValue: '#ffffff00',
        },
        {
            name: 'secondaryButtons',
            type: 'list',
            onChange: (options) => {
                if (options.get('secondaryButtons').length > 4) {
                    options.set('secondaryButtons', options.get('secondaryButtons').slice(0, 4));
                    alert("can't be more than 4");
                }
            },
            subFields: [
                {
                    name: 'text',
                    type: 'string',
                    defaultValue: '[Label]',
                    required: true,
                },
                {
                    name: 'href',
                    type: 'string',
                    defaultValue: '/',
                    required: true,
                },
                {
                    name: 'color',
                    type: 'string',
                    defaultValue: 'secondary',
                    enum: ['primary', 'secondary', 'light'],
                },
                {
                    name: 'size',
                    type: 'string',
                    defaultValue: 'small',
                    enum: ['small', 'medium', 'large'],
                },
                {
                    name: 'variant',
                    type: 'string',
                    defaultValue: 'outlined',
                    enum: ['contained', 'outlined'],
                },
            ],
        },
        ...callToActionInputs,
        ...secondaryCallToActionInputs,
    ],
};
