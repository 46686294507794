export const config = {
    name: 'Moods',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            required: true,
            defaultValue: 'Moods',
        },
        {
            name: 'moods',
            type: 'list',
            required: true,
            helperText: 'A list of moods that links to the search page',
            defaultValue: [
                {
                    label: 'Happy',
                    href: '/audio/search?media-type=music&categories=happy',
                    imageSrc: '/browse/music/happy.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Dark',
                    href: '/audio/search?media-type=music&categories=dark',
                    imageSrc: '/browse/music/dark.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Love',
                    href: '/audio/search?media-type=music&categories=love',
                    imageSrc: '/browse/music/love.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Relaxing',
                    href: '/audio/search?media-type=music&categories=relaxing',
                    imageSrc: '/browse/music/relaxing.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Playful',
                    href: '/audio/search?media-type=music&categories=playful',
                    imageSrc: '/browse/music/playful.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Angry',
                    href: '/audio/search?media-type=music&categories=angry',
                    imageSrc: '/browse/music/angry.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Inspiring',
                    href: '/audio/search?media-type=music&categories=inspiring',
                    imageSrc: '/browse/music/inspiring.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Sad',
                    href: '/audio/search?media-type=music&categories=sad',
                    imageSrc: '/browse/music/sad.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Epic',
                    href: '/audio/search?media-type=music&categories=epic',
                    imageSrc: '/browse/music/epic.jpg',
                    showNewLabel: false,
                },
                {
                    label: 'Serious',
                    href: '/audio/search?media-type=music&categories=serious',
                    imageSrc: '/browse/music/serious.jpg',
                    showNewLabel: false,
                },
            ],
            subFields: [
                {
                    name: 'label',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'href',
                    type: 'string',
                    required: true,
                },
                {
                    friendlyName: 'Image Source',
                    name: 'imageSrc',
                    type: 'string',
                    required: true,
                    helperText: "Path to image asset, relative to '/assets/common/images'",
                    regex: {
                        pattern: '^/.*',
                        options: '',
                        message:
                            "Make sure you include the leading slash and the path is relative to '/assets/common/images'",
                    },
                },
                {
                    name: 'showNewLabel',
                    type: 'boolean',
                    required: true,
                    defaultValue: false,
                },
            ],
        },
    ],
};
